import React from 'react';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Scroll from '../components/scroll';
import Sponsors from '../components/sponsors';
import Timetable from '../components/timetable';
import Keynotes from '../components/keynotes';
import Team from '../components/team';
import Map from '../components/map';
import logo from '../assets/images/barcamp_logo.svg';

import Img from 'gatsby-image';

const IndexPage = ({ data }) => {
  let node = data.allDirectusEdition.edges[0].node;
  console.log(node);

  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <h2>
            <img className="banner-image" src={logo} alt="logo" />
          </h2>
          <h3>{node.subtitle}</h3>

          {(node.end_date === node.date) ? (
          <p>
            {' '}
            {dayjs(node.end_date)
              .locale(de)
              .format('DD. MMMM YYYY')}
          </p>
          ) : (
          <p>
            {' '}
            {dayjs(node.date)
              .locale(de)
              .format('DD. ')}{' '}
            -{' '}
            {dayjs(node.end_date)
              .locale(de)
              .format('DD. MMMM YYYY')}
          </p>
          )}
          <ul className="actions special">
            {node.hero_buttons &&
            node.hero_buttons.map(b => (<li key={b.button_text}>
              <a href={b.button_url} className="button primary">
                {b.button_text}
              </a>
            </li>))
            }
          </ul>
        </div>
        <Scroll type="id" element="four">
          <a href="#four" className="more">
          Schedule
          </a>
        </Scroll>
      </section>

      <section id="two" className="wrapper alt style2">
        {node.textblock &&
          node.textblock.map(block => (
            <section className="spotlight" key={block.heading}>
              <div className="image">
                {block.image.localFile.childImageSharp ? (
                  <Img
                    alt="Bild zu {block.heading}"
                    className="card-img-top"
                    fluid={block.image.localFile.childImageSharp.fluid}
                  />
                ) : (
                  <img
                    src={block.image.localFile.publicURL}
                    alt="Bild zu {block.heading}"
                    className="card-img-top"
                  />
                )}
              </div>
              <div className="content">
                <h2>{block.heading}</h2>
                <div dangerouslySetInnerHTML={{ __html: block.text }}></div>
              </div>
            </section>
          ))}
      </section>

      {node.keynotes.length > 0 && (
        <section id="three" className="wrapper style2 special">
          <div className="inner">
            <header className="major">
              <h2>Keynotes</h2>
            </header>
            <Keynotes items={node.keynotes} />
          </div>
        </section>
      )}

      <section id="four" className="wrapper style3 special">
        <div className="inner">
          <header className="major">
            <h2>Schedule</h2>
          </header>
          <Timetable items={node.zeitplan} />
        </div>
      </section>

      <section id="five" className="wrapper style5 special">
        <div className="inner">
          <header className="major">
            <h2>Sponsors</h2>
            <p>This event is free for participants - thanks to our sponsors!</p>
          </header>
          <Sponsors items={node.sponsors} />
        </div>
        <div className="inner">
          <header>
            <h2>Be a Sponsor!</h2>
            <p>
              We need your support.{' '}
            </p>
            <a
              href={node.sponsor_pdf.localFile.publicURL}
              className="button fit primary"
            >
              Sponsoring Leaflet
            </a>
          </header>
        </div>
      </section>

      <section id="six" className="wrapper style2 special">
        <div className="inner">
          <header className="major">
            <h2>The Team</h2>
          </header>
          <Team items={node.team} />
        </div>
      </section>

      <Map location={node.location} />

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h2>Join us!</h2>
            <p>
              Give a talk on web development, UX, game development, VR, or any
              other topic that pleases the crowd. Learn from 100 other
              developers and designers. Have fun!
            </p>
          </header>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomePageQuery {
    allDirectusEdition(sort: { fields: date, order: DESC }, limit: 1) {
      edges {
        node {
          id
          subtitle
          date
          end_date
          hero_buttons {
            button_text
            button_url
          }
          zeitplan {
            day
            slots {
              title
            }
          }
          textblock {
            text
            image {
              localFile {
                publicURL
              }
            }
            heading
          }
          sponsors {
            logo {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 400, quality: 75) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
            link
            title
          }
          sponsor_pdf {
            localFile {
              publicURL
            }
          }
          keynotes {
            title
            role
            name
            link
            content
            portrait {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 75) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
          team {
            name
            link
            portrait {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 400, quality: 75) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
            role
          }
          location {
            lat
            lng
          }
        }
      }
    }
  }
`;
