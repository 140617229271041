import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { icon } from 'leaflet/src/layer/marker/Icon';

export default class MyMap extends Component {
  constructor() {
    super();
    this.state = {
      lat: 47.7234727,
      lng: 13.0871046,
      zoom: 15,
    };
  }

  openPopup(marker) {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup();
      });
    }
  }

  render() {
    const position = this.props.location || [this.state.lat, this.state.lng];
    if (typeof window !== 'undefined') {
      let customIcon = icon({
        iconUrl:
          "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 512 512'%3E %3Cpath d='M256 32c-79.529 0-144 64.471-144 144 0 112 144 304 144 304s144-192 144-304c0-79.529-64.471-144-144-144zm0 190.9c-25.9 0-46.9-21-46.9-46.9s21-46.9 46.9-46.9 46.9 21 46.9 46.9-21 46.9-46.9 46.9z' fill='%23E3147E'/%3E %3C/svg%3E",
        iconSize: [45, 80],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76],
        shadowUrl: '',
        shadowSize: [0, 0],
      });
      return (
        <Map center={position} zoom={this.state.zoom}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={position} icon={customIcon} ref={this.openPopup}>
            <Popup>
              HS017
              <br />
              <br />
              Fachhochschule Salzburg <br />
              Urstein Süd 1 <br />
              A-5412 Puch/Hallein <br />
            </Popup>
          </Marker>
        </Map>
      );
    }

    return null;
  }
}
